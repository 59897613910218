<template>
  <div class="col mt-3">
    <div class="row">
      <div class="col-md-3">
        <div
          class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-purple"
        >
          <p class="highlights-title">text here</p>
          <h2 class="u-mb-zero hightlight-number">9,343,132</h2>
        </div>
      </div>

      <div class="col-md-3">
        <div
          class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-green"
        >
          <p class="highlights-title">text here</p>
          <h2 class="u-mb-zero hightlight-number ss-green-font-color">9,343,132</h2>
        </div>
      </div>

      <div class="col-md-3">
        <div
          class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-red"
        >
          <p class="highlights-title">text here</p>
          <h2 class="u-mb-zero hightlight-number ss-red-font-color">9,343,132</h2>
        </div>
      </div>

      <div class="col-md-3">
        <div
          class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-orange"
        >
          <p class="highlights-title">text here</p>
          <h2 class="u-mb-zero hightlight-number ss-orange-font-color">9,343,132</h2>
        </div>
      </div>

      <div class="col-md-12 mt-4">
        <h6 class="text-dark font-weight-normal">Title here</h6>
        <div
          class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small"
        >
          <h1>graph here</h1>
        </div>
      </div>

      <div class="col-md-6 mt-4">
        <h6 class="text-dark font-weight-normal">Title here</h6>
        <div
          class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small"
        >
          <h1>graph here</h1>
        </div>
      </div>

      <div class="col-md-6 mt-4">
        <h6 class="text-dark font-weight-normal">Title here</h6>
        <div
          class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small"
        >
          <h1>graph here</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "graphs",
  components: {},
  data() {
    return {};
  },
  computed: {}
};
</script>
