<template>
  <div class="d-flex map-container-height">
    <mapSidebar></mapSidebar>
    <b-tabs class="w-100 map-topbar">
      <b-tab title="Map" active>
        <mapContent></mapContent>
      </b-tab>
      <b-tab title="Graphs">
        <mapGraphs></mapGraphs>
      </b-tab>
      <b-tab title="tab">something here</b-tab>
    </b-tabs>
  </div>
</template>

<script>
import mapSidebar from "@/views/map/map-sidebar.vue";
import mapContent from "@/views/map/map-content.vue";
import mapGraphs from "@/views/map/graphs.vue";

export default {
  name: "Dashboard",
  components: {
    mapSidebar,
    mapContent,
    mapGraphs
  },
  data() {
    return {};
  },
  computed: {}
};
</script>
