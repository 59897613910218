<template>
  <div class="map-height">
    <!-- floating info box -->
    <div class="floating-info-box py-1">
      <div class="scrollbar content scrollable-div px-3">
        <p class="text-dark font-weight-bold">Title here</p>
        <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small d-flex border mb-3">
          <p class="mb-0">Something here</p>
          <span class="text-muted small ml-auto">abc</span>
        </div>
        <div v-for="n in 5" :key="n" class="dash-grey-card c-card u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small mb-3">
          <h5 class="ss-primary-text">Work Order (WO1658) - New Service Call</h5>
          <p class="small mb-0"><strong>Canadian Rockies Landscape</strong>- British Columbia Branch - Canadian Rockies Landscape Road F. , Cranbrook</p>
          <div class="d-flex justify-content-between mt-2">
            <div>
              <span class="small text-muted">Start date</span>
              <p class="mb-0">Aug-06-2020</p>
            </div>
            <div class="text-right">
              <span class="small text-muted">Completion date</span>
              <p class="mb-0">Aug-08-2020</p>
            </div>
          </div>

          <div class="d-flex justify-content-between mt-2">
            <div>
              <span class="small text-muted">Status/Priority</span>
              <span class="badge mt-1" style="background-color:#F0AD4E;">New Service Call</span>
            </div>

            <div class="text-right">
              <span class="small text-muted">Start date</span>
              <span class="badge badge-warning mt-1">07-Aug-2020</span>
            </div>
          </div>

          <div class="d-flex mt-2">
            <div>
              <span class="small text-muted d-block">Technician Status</span>
              <span class="badge dispatched mt-1" style="background-color:#b8c1ce;">Dispatched</span>
            </div>
            <!-- <span class="small text-muted">
              Start date
              <span class="badge badge-warning mt-1">07-Aug-2020</span>
            </span>-->
          </div>
        </div>
      </div>
    </div>
    <AzureMap class="azure-map" :pitch="60" :zoom="14" @ready="setMap">
      <!-- Create a data source to store the route coordinates -->
      <AzureMapDataSource>
        <AzureMapLineString v-if="routeCoordinates" :coordinates="routeCoordinates" />
        <AzureMapLineLayer :options="routeLineLayerOptions" />
      </AzureMapDataSource>

      <!-- Create a data source to store the target coordinates -->
      <AzureMapDataSource>
        <AzureMapPoint :longitude="targetLongitude" :latitude="targetLatitude" />
        <AzureMapSymbolLayer :options="targetSymbolLayerOptions" />
      </AzureMapDataSource>

      <!-- Show user position with an optional accuracy circle -->
      <AzureMapUserPosition
        :symbol-layer-options="userPositionSymbolLayerOptions"
        :center-map-to-user-position="true"
        :camera-options="userPositionCameraOptions"
        :show-accuracy="true"
        :enable-high-accuracy="true"
        :polygon-layer-options="userPositionPolygonLayerOptions"
      />
    </AzureMap>
  </div>
</template>

<script>
  // import "vue-azure-maps/dist/vue-azure-maps.css";
  import {AzureMap, AzureMapDataSource, AzureMapLineString, AzureMapLineLayer, AzureMapPoint, AzureMapSymbolLayer, AzureMapUserPosition} from "vue-azure-maps";

  export default {
    name: "RouteExample",

    components: {
      AzureMap,
      AzureMapDataSource,
      AzureMapLineString,
      AzureMapLineLayer,
      AzureMapPoint,
      AzureMapSymbolLayer,
      AzureMapUserPosition,
    },

    data() {
      return {
        buildingIcon: require(`@/assets/images/map/building-1.png`),
        pin: require(`@/assets/images/map/pin-1.png`),
        map: null,
        routeCoordinates: null,
        targetLongitude: 79.3832,
        targetLatitude: 43.6532,
        restRoutingRequestUrl: "https://atlas.microsoft.com/route/directions/json?api-version=1.0&subscription-key={subscription-key}&query={query}&travelMode=car",

        targetSymbolLayerOptions: {
          iconOptions: {
            image: "buildingIcon",
            size: 0.09,
          },
        },

        routeLineLayerOptions: {
          strokeColor: "#6e0677",
          strokeWidth: 8,
          lineJoin: "round",
          lineCap: "round",
        },

        userPositionSymbolLayerOptions: {
          iconOptions: {
            image: "pinIcon",
            size: 0.09,
          },
        },

        // doesnt work but its called in the component
        userPositionCameraOptions: {
          zoom: 5,
        },

        userPositionPolygonLayerOptions: {
          fillColor: "dodgerblue",
          opacity: 0.5,
        },
      };
    },

    methods: {
      setMap(mapEvent) {
        this.map = mapEvent.map;
        this.map.imageSprite.add("buildingIcon", this.buildingIcon);
        this.map.imageSprite.add("pinIcon", this.pin);
      },

      // async generateRoute(userPosition) {
      //   let { coords } = userPosition;

      //   let { targetLongitude, targetLatitude } = this;

      //   let requestUrl = this.restRoutingRequestUrl
      //     .replace("{subscription-key}", this.$_azureMaps.key)
      //     .replace(
      //       "{query}",
      //       `${coords.latitude},${coords.longitude}:${targetLatitude},${targetLongitude}`
      //     );

      //     console.log("Target url",requestUrl);

      //   let data = await fetch(requestUrl).then(response => response.json());

      //   //create an array to store the coordinates of each turn
      //   this.routeCoordinates = [];

      //   var route = data.routes[0];

      //   //create an array to store the coordinates of each turn
      //   for (var legIndex = 0; legIndex < route.legs.length; legIndex++) {
      //     var leg = route.legs[legIndex];
      //     var legCoordinates = leg.points.map(point => {
      //       return [point.longitude, point.latitude];
      //     });
      //     //add each turn coordinate to the array
      //     this.routeCoordinates = this.routeCoordinates.concat(legCoordinates);
      //   }

      //   if (this.map && targetLongitude && targetLatitude) {
      //     this.map.setCamera({
      //       bounds: this.$_azureMaps.atlas.data.BoundingBox.fromPositions([
      //         [coords.longitude, coords.latitude],
      //         [targetLongitude, targetLatitude]
      //       ]),
      //       padding: 35
      //     });
      //   }
      // }
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .azure-map {
    width: 100%;
    height: 100%;
  }
</style>
