<template>
  <div class="sidebar-map pt-4">
    <div class="col">
      <a href="#" class="rounded-float-btn ss-primary-font-color">
        Text here
        <i class="fa fa-chevron-right small"></i>
      </a>
      <p class="mt-3 mb-2">Text here</p>
      <p class="text-muted small mb-2">Text here</p>
      <h2 class="text-danger">19,8232,990</h2>
      <p class="mb-2">
        <i class="fa fa-circle small ss-primary-font-color"></i> Text line 1
      </p>
      <p class="mb-2">
        <i class="fa fa-circle small text-info"></i> Text line 2
      </p>
      <p class="mb-2">
        <i class="fa fa-circle small text-warning"></i> Text line 3
      </p>
      <div class="border-bottom mt-4"></div>
    </div>
    <div class="scrollbar scrollable-div u-height-100vh">
      <div class="col pt-4">
        <b-nav-form class="mb-3">
          <b-form-input
            size="lg"
            placeholder="Search..."
            class="w-100"
          ></b-form-input>
        </b-nav-form>
        <div
          class="c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small d-flex mb-2 clickable"
          v-for="n in 15"
          :key="n"
        >
          <p class="mb-0">Technician</p>
          <span class="text-muted small ml-auto">{{ n }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>
